import React, { lazy, Suspense, useCallback, useMemo } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { useOpenAdminInfoPanel } from '@/components/AdminInfoPanel/AdminInfoPanel';
import PageWrapper from '@bit/together-price.core.components.page-wrapper';
import {
  Box,
  CircularProgress,
  Hidden,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import useSearchInput from '@/hooks/useSearchInput';
import { useNavigateToUrl } from '@bit/together-price.core.components.app-context';
import useSearchTitle from '@/hooks/search/useSearchTitle';
import { useRecoilValue } from 'recoil';
import { apiState, categoriesState, tagsState } from '@/recoil/atoms/search';
import { useSetSharingPreviewPanel } from '@/components/SharingPreviewPanel/SharingPreviewPanel';
import {
  DEFAULT_PUBLIC_SEARCH_API_URL,
  DEFAULT_SEARCH_API_URL,
} from '@/constants/search';
import { autoFocusedSearchState } from '@/recoil/atoms/app';
import SearchStateObserver from '@/components/SearchStateObserver';

const SearchTabs = lazy(() => import('@/components/SearchTabs'));
const SearchPeopleResults = lazy(
  () => import('@/components/SearchPeopleResults')
);
const TopPublicBar = lazy(() => import('@/components/TopPublicBar'));
const SearchResults = lazy(
  () => import('../../../components/SearchGroupsResult')
);
const SearchAllResults = lazy(() => import('@/components/SearchAllResults'));

const FullSpinner = () => {
  return (
    <Box
      flex={1}
      width='100%'
      display='flex'
      alignItems='center'
      justifyContent='center'
    >
      <CircularProgress color='primary' size={40} />
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  title: {},
  mobileInner: {
    padding: theme.spacing(1, 0),
  },
}));

export type SearchProps = {
  usePublicNetwork?: boolean;
  marketCountry?: string;
};
const Search: React.FC<SearchProps> = ({
  usePublicNetwork = false,
  marketCountry: defaultMarketCountry,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const navigateToUrl = useNavigateToUrl();
  const openAdminInfoPanel = useOpenAdminInfoPanel();
  const [title] = useSearchTitle(defaultMarketCountry, usePublicNetwork);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const sharingPreviewPanel = useSetSharingPreviewPanel();

  const { defaultValue, handleSearchChange } = useSearchInput();
  const tags = useRecoilValue(tagsState);
  const categories = useRecoilValue(categoriesState);
  const searchApiUrl = useRecoilValue(apiState);
  const isFocused = useRecoilValue(autoFocusedSearchState);

  const onBack = useCallback(() => {
    if (history.length > 0) {
      return history.goBack();
    }
    return history.push('/');
  }, [history]);

  const showSearch = useMemo(
    () =>
      !(
        !!tags.length ||
        !!categories.length ||
        (searchApiUrl !== DEFAULT_SEARCH_API_URL &&
          searchApiUrl !== DEFAULT_PUBLIC_SEARCH_API_URL)
      ),
    [tags, categories, searchApiUrl]
  );

  const onClickPost = useCallback(
    (post) => {
      if (usePublicNetwork) {
        sharingPreviewPanel(post);
        return;
      }
      navigateToUrl(`/post/${post.sharingPostId}`);
    },
    [navigateToUrl, sharingPreviewPanel, usePublicNetwork]
  );

  const onClickPostAdmin = useCallback(
    (post) => {
      const { admin, sharingPostId } = post;
      if (usePublicNetwork) {
        sharingPreviewPanel(post);
        return;
      }

      openAdminInfoPanel({
        userId: admin.userId,
        sharingPostId,
        trust: admin.trust,
        lastAccess: admin.lastSession,
        avgResponseTime: admin.adminAvgTime,
      });
    },
    [openAdminInfoPanel, sharingPreviewPanel, usePublicNetwork]
  );

  const fullTopAppBarProps = useMemo(
    () => ({
      mobileProps: {
        title,
        classes: {
          title: classes.title,
        },
        onBack,
        showSearch,
      },
      searchProps: {
        defaultValue,
        autoFocus: isFocused,
        onChange: handleSearchChange,
      },
    }),
    [
      classes.title,
      defaultValue,
      handleSearchChange,
      onBack,
      showSearch,
      title,
      isFocused,
    ]
  );

  const pageClasses = useMemo(
    () => ({
      mobileInner: classes.mobileInner,
    }),
    [classes.mobileInner]
  );

  return (
    <>
      {usePublicNetwork && (
        <Suspense fallback={null}>
          <TopPublicBar />
        </Suspense>
      )}
      <PageWrapper
        isPublic={usePublicNetwork}
        activeTab='home'
        navigateToUrl={navigateToUrl}
        FullTopAppBarProps={fullTopAppBarProps}
        classes={pageClasses}
        headerChildren={
          showSearch && (
            <Hidden mdUp>
              <Suspense fallback={null}>
                <SearchTabs />
              </Suspense>
            </Hidden>
          )
        }
      >
        <SearchStateObserver />
        <Switch>
          <Route
            path={['/search/groups', isDesktop && '/search'].filter(Boolean)}
            exact={!isDesktop}
          >
            <Suspense fallback={<FullSpinner />}>
              <SearchResults
                marketCountry={defaultMarketCountry}
                noPromo={usePublicNetwork}
                onClickPost={onClickPost}
                onClickPostAdmin={onClickPostAdmin}
              />
            </Suspense>
          </Route>
          <Route path={!isDesktop && '/search/people'} exact>
            <Suspense fallback={<FullSpinner />}>
              <SearchPeopleResults onClickAdmin={onClickPostAdmin} />
            </Suspense>
          </Route>
          <Route
            path={!isDesktop && ['/search', '/search/all']}
            component={SearchAllResults}
          >
            <Suspense fallback={<FullSpinner />}>
              <SearchAllResults
                onClickPost={onClickPost}
                onClickPostAdmin={onClickPostAdmin}
              />
            </Suspense>
          </Route>
        </Switch>
      </PageWrapper>
    </>
  );
};

export default Search;
